<template>
  <div class="contact-cta-box">
    <div class="contact-cta-box__heading">
      <h2
        v-if="header"
        class="contact-cta-box__header"
        v-html="header"
      />

      <p
        v-if="subheader"
        class="contact-cta-box__subheader"
        v-html="subheader"
      />
    </div>

    <div class="contact-cta-box__content-wrapper">
      <T3HtmlParser
        v-if="bodytext"
        :content="bodytext"
        class="contact-cta-box__content"
      />

      <ButtonBase
        :id="headerLink"
        class="contact-cta-box__btn"
        :button="customButtonProps"
        @click="handleCalendarOpen"
      />
    </div>

    <div
      v-if="isImage"
      class="contact-cta-box__image-wrapper"
    >
      <UiImg
        :image="image"
        class="contact-cta-box__image"
        use-image-size
      />
    </div>

    <Calendar
      :is-open="isCalendarOpen"
      :calendar-id="calendarId"
      @close="handleCalendarClose"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'

import { ButtonBase, UiImg } from '~ui/components'
import Calendar from '~ui/components/Base/Calendar/Calendar.vue'
import type { UiCeCaseStudyBannerProps } from '~ui/types'

const props = defineProps<UiCeCaseStudyBannerProps>()

const isImage = computed(() => props.image.length)

const customButtonProps = computed(() => ({ ...props.button, link: '' }))
const calendarId = computed(() => (props.button?.link as string) || null)

const isCalendarOpen = ref(false)
const handleCalendarOpen = () => isCalendarOpen.value = true
const handleCalendarClose = () => isCalendarOpen.value = false
</script>

<style lang="scss">
.contact-cta-box {
  $contact-cta-box-padding: rem(24px);

  padding: $contact-cta-box-padding;
  border-radius: em(4px);
  overflow: hidden;
  display: grid;
  justify-content: space-between;
  grid-template: 1fr / 1fr;
  grid-template-areas:
    'image'
    'heading'
    'content';
  gap: rem(20px);
  background-color: color(primary);
  color: color(white);

  @include media-query(sm) {
    grid-template: repeat(2, auto) / 1.5fr 1fr;
    grid-template-areas:
      'heading image'
      'content image';
    gap: rem(40px) rem(10px);
  }

  &__heading {
    grid-area: heading;
  }

  &__header {
    font-weight: 600;
    font-size: rem(35px);
    margin-block: rem(4px) rem(20px);
  }

  &__subheader {
    font-size: rem(18px);
    opacity: 0.8;
    margin: 0;
  }

  &__content {
    &-wrapper {
      grid-area: content;
      padding: $contact-cta-box-padding;
      border-radius: em(4px);
      display: flex;
      flex-direction: column;
      background: linear-gradient(
        to right,
        hsl(0 0% 100% / 0.32),
        transparent 85%
      );
      position: relative;
      isolation: isolate;

      &::before {
        content: '';
        position: absolute;
        inset: rem(2px);
        background-color: color(primary);
        z-index: z-index(below);
      }
    }

    & p > strong {
      font-size: rem(28px);
    }

    & p {
      font-size: rem(16px);
    }

    & p:last-child {
      display: flex;
      flex-wrap: wrap;
      gap: rem(8px) rem(16px);
      margin-top: rem(6px);
    }

    & a {
      position: relative;
      display: inline-flex;
      align-items: center;
      gap: rem(12px);
      font-size: rem(18px);
      font-weight: 500;
      color: color(white);

      &:is(:hover, :focus) {
        text-decoration: underline;
        color: color(white);
      }

      &::before {
        display: inline-block;
        position: relative;
        background-color: hsl(0 0% 100% / 16%);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 50%;
        height: rem(32px);
        width: rem(32px);
        border-radius: em(2px);
        text-align: center;
        line-height: 1.8;
        pointer-events: none;
        filter: brightness(0) invert(1);
      }

      &[href^='tel:']::before {
        content: '';
        background-image: url('~ui/assets/icons/icon-phone.svg');
      }

      &[href^='mailto:']::before {
        content: '';
        background-image: url('~ui/assets/icons/icon-envelope.svg');
      }
    }
  }

  &__btn.button-base {
    margin: rem(8px) auto 0 0;
    width: fit-content;
  }

  &__image {
    &-wrapper {
      grid-area: image;
      background-color: hsl(0 0% 100% / 0.08);
      background-image: url('~ui/assets/background-maco.png');
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: em(4px);
      display: flex;
      justify-content: center;
      position: relative;
    }

    @include media-query(sm) {
      position: absolute;
      bottom: calc($contact-cta-box-padding * -1);
    }
  }

  & .meetings-iframe-container + .close--on-full-modal {
    translate: 0;
    top: 16px;
    right: 4px;

    @include media-query(860px) {
      right: calc(50% - (850px / 2));
    }
  }
}
</style>
